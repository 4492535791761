// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$green: #79ac33;
$green-dark: #3d4609;
$green-light: #e2f0cf;
$blue: #488ddb;
$blue-dark: #044389;
$white: #fff;
$black: #000;
$gray: #575B70;
$pink: #ED2A7B;

$cta-green: #8DC63F;

$gray-light: #F8F6F3;


// theme-colors
$primary: $gray;
$secondary: $green;
$tertiary: $blue;

// body
$body-bg: $white;
$body-color: $gray;

// links
$link-color: $primary;
$link-decoration: underline;

// typography
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&display=swap');
$font-family-primary: 'Open Sans', sans-serif;
$font-family-secondary: 'Open Sans', sans-serif;

$font-family-cave: 'Caveat', cursive;

$font-awesome: "Font Awesome 5 Pro";
$font-family-base: $font-family-primary;

$font-size-base: 1rem; // 16px


$h1-font-size: $font-size-base * 2.5; // 40px
$h2-font-size: $font-size-base * 2; // 32px
$h3-font-size: $font-size-base * 1.75; // 28px
$h4-font-size: $font-size-base * 1.5; // 24px
$h5-font-size: $font-size-base * 1.25; // 20px
$h6-font-size: $font-size-base * 0.875; // 14px

$font-size-small: $font-size-base * 0.875; // 14px
$font-size-big: $font-size-base * 1.25; // 20px

$headings-font-family: $font-family-secondary;
$headings-font-weight: 800;
$headings-color: $black;

// contextual
$light: $green;
$dark: $green-dark;
$text-light: $white;
$text-dark: $black;

// matrix-colors
$matrix-color-primary: $primary;
$matrix-color-secondary: $secondary;
$matrix-color-tertiary: $tertiary;

// shadow
$shadow: 0 3px 6px rgba($black, 0.15);

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;

*/

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1400px
) !default;


// grid containers
$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1210px,
	xxl: 1210px,
) !default;

$grid-breakpoints-big: (
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1600px,
	xxxl: 1920px
) !default;

$container-max-widths-big: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1000px,
	xxl: 1440px,
	xxxl: 1440px
) !default;
