.page-block.form {
	padding: 0;
	border: none;

	.form-group {
		position: relative;

		input.form-control,
		textarea.form-control {
			display: block;
			width: 100%;
			height: calc(1.25em + 1.75rem + 2px);
			padding: 0.875rem 1.875rem;
			font-size: 1rem;
			font-weight: 400;
			line-height: 1.25;
			color: #575B70;
			background-color: #fff;
			background-clip: padding-box;
			border-radius: 1.5625rem;
			transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
			padding-top: 1.3125rem;
			padding-bottom: 0.4375rem;
		}

		textarea.form-control {
			min-height: 200px;
		}

		.form-control-label {
			cursor: text;
			font-size: 0.6875rem;
			font-weight: 700;
			left: 1.875rem;
			line-height: 1.25;
			opacity: 1;
			padding: 0 1px;
			position: absolute;
			top: 0.4375rem;
			transition: all 0.2s;
			max-width: calc(100% - 3.75rem);
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			pointer-events: none;
		}

		&:not(.focused) {
			.form-control-label {
				font-size: 1rem;
				font-weight: 400;
				top: 0.875rem;
			}
		}
	}

	.form-group {
		.btn-primary {
			background: $secondary;
			border: $secondary;
			color: #fff;
		}
	}
}