.image-collection {
	.gallery-grid.grid-region {
		position: relative;
		left: 150px;
		width: 560px;
		height: 322px;
		@media(max-width: 1599px) {
			left: 0;
		}
		@include media-breakpoint-down(md) {
			width: 100%;
		}

		.gallery-item {
			width: 100%;
			flex: 0 0 100%;
			max-width: 245px;
			height: 245px;
			padding: 0;
			margin-bottom: 0;
			position: absolute;

			a, picture, img {
				height: 100%;
			}

			a {
				border-radius: 50%;
				overflow: hidden;
			}

			.gallery-caption {
				display: none;
			}

			&:nth-of-type(1) {
				top: -100px;
				left: 60px;
			}

			&:nth-of-type(2) {
				max-width: 187px;
				height: 187px;
				left: 170px;
				top: 180px;
				@include media-breakpoint-down(md) {
					left: 185px;
				}
			}

			&:nth-of-type(3) {
				max-width: 162px;
				height: 162px;
				left: 400px;
				top: 120px;
				@include media-breakpoint-down(md) {
					left: 15px;
					top: 150px;
				}
			}
		}

		.crkl {
			display: block;
			position: absolute;
			border-radius: 50%;
		}

		.crkl-1 {
			width: 6.429%;
			padding-bottom: 6.429%;
			left: -7.679%;
			top: 31.677%;
			background: #85CBF0;
		}

		.crkl-2 {
			width: 13.214%;
			padding-bottom: 13.214%;
			left: -8.929%;
			bottom: -5.28%;
			background: #F37D67;
		}

		.crkl-3 {
			width: 9.107%;
			padding-bottom: 9.107%;
			left: 16.786%;
			top: 65.839%;
			background: #ED2A7B;
			@include media-breakpoint-down(md) {
				display: none;
			}
		}

		.crkl-4 {
			width: 7.5%;
			padding-bottom: 7.679%;
			left: 64.464%;
			top: 24.224%;
			background: #ED2A7B;
		}

		.crkl-5 {
			width: 11.429%;
			padding-bottom: 11.429%;
			left: 84.286%;
			top: -7.453%;
			background: #5C7D38;
		}

		.crkl-6 {
			width: 4.286%;
			padding-bottom: 4.286%;
			left: 96.964%;
			top: 24.534%;
			background: #fff;
		}

		.crkl-7 {
			width: 6.429%;
			padding-bottom: 6.429%;
			left: 101.25%;
			bottom: -5.59%;
			background: #85CBF0;
		}

	}
}
