// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 425px;
	max-height: 540px;
	@include media-breakpoint-down(xl) {
		min-height: 425px;
		max-height: 425px;
	}
}

.eyecatcher.large {
	margin-top: 80px;
	@include media-breakpoint-down(xl) {
		margin-top: 60px;
	}
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 85vh;
	min-height: 515px;
	max-height: 515px;

	@include media-breakpoint-up(sm) {
		height: 100vh;
	}
	@media(max-width: 767px) {
		max-height: 300px;
		height: 300px;
		min-height: 300px;
	}

	.overlay-container {
		@media(min-width: 768px) {
			position: absolute;
			top: 0;
			z-index: +1;
			width: 100%;
			display: flex;
			justify-content: flex-end;
			max-width: 100%;
		}

		.custom-html {
			@media(max-width: 767px) {
				display: none;
			}
		}
	}
}

.eyecatcher:not(.large) {
	position: relative;

	.overlay-container {
		position: absolute;
		top: auto;
		bottom: 0;
		-webkit-transform: scaleX(-1);
		transform: scaleX(-1);
		z-index: +1;
		right: 0;

		> .container-holder {
			margin: 0 !important;

			.column {
				padding: 0;
			}
		}
	}
}

.slider {
	.owl-stage-outer {
		overflow: visible;

		.owl-item {
			padding: 0 1.275rem;
			@include media-breakpoint-up(xl) {
				margin-right: 0 !important;
			}

			.item {
				border-radius: 0 2.5rem 0 0;
				overflow: hidden;
			}
		}
	}
}

.big-content-banner {
	border-radius: 0 2.5rem 0 0;
	overflow: hidden;

	.owl-stage-outer {
		.owl-stage {
			.owl-item {
				.item {
					min-height: 615px;
				}
			}
		}
	}
}


.overlay-section {
	position: relative;
	z-index: 1;

	@media(min-width: 768px) {
		margin-top: -515px;
		min-height: 515px;
		max-height: 515px;
	}
	@media(max-width: 767px) {
		background: $cta-green;
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.custom-html {
		position: absolute;
		top: -80px;
		right: -100px;
		@media(max-width: 1199px) {
			transform: scale(.7);
		}
		@media(max-width: 767px) {
			display: none;
		}

		&.banner-circels {
			right: auto;
			top: 190px;
			left: -370px;
		}
	}

	.wysiwyg {
		@media(max-width: 1199px) {
			right: 30px !important;
		}
		@media(min-width: 768px) {
			position: absolute;
			top: 220px;
			right: 60px;
			max-width: 425px;
		}

		h2 {
			color: #fff;
			font-size: 40px;
			margin-bottom: 25px;
			@media(max-width: 1199px) {
				font-size: 25px;
			}
			@media(max-width: 767px) {
				font-size: 28px;
			}
		}

		.btn {
			&:not(:hover) {
				background: #fff;
				color: $gray;
				border-color: #fff;

				&:after {
					content: url('/images/arrow-gray.svg');
				}
			}
		}
	}
}

&.bundle-detail {
	.carousel {
		border-top-right-radius: 50px;
		overflow: hidden;

		.owl-item {
			.item {
				min-height: 400px;
			}
		}
	}
}

.owl-carousel.slider .owl-nav .owl-prev {
	@include media-breakpoint-down(md) {
		left: 0;
	}
}

.owl-carousel.slider .owl-nav .owl-next {
	@include media-breakpoint-down(md) {
		right: 0;
	}
}
