// header
.header {
	z-index: 998;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: $white;
	transition: 0.5s;

	&.sticky {
		box-shadow: $shadow;
	}

	> [class*="container-"] {
		padding: 0 !important;

		.container-holder {
			> .column {
				height: 80px;
				@extend .align-items-center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}
				@include media-breakpoint-down(xl) {
					height: 60px;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		margin-left: auto;

		i {
			font-weight: 100;
			font-size: 32px;
			color: #000;
		}

		.navbar-toggler[aria-expanded='true'] {
			i {
				&:before {
					content: '\f057';
				}
			}
		}
	}

	// logo
	.logo {
		width: 224px;
		margin: 0;
		position: absolute;
		top: 0;
		background: #fff;
		border-radius: 0 0 12px 12px;
		box-shadow: 0 8px 10px rgba(0, 0, 0, .10);
		z-index: 100;
		@include media-breakpoint-down(xl) {
			max-width: 167px;
			top: 15px;
		}

		a {
			padding: 15px 20px 10px;
			@extend .d-block;
			@include media-breakpoint-down(xl) {
				padding: 0 10px 10px;
			}

			img {
				@extend .w-100;
			}
		}
	}

	// menu
	.menu {
		justify-content: flex-end;
		@include media-breakpoint-down(lg) {
			background: #fff;
			top: 59px;
			position: fixed;
			left: 0;
			width: 100%;
		}

		@include media-breakpoint-up(xl) {
		}

		.navbar-nav {
			@include media-breakpoint-down(lg) {
				padding-top: 70px;
				padding-bottom: 35px;
			}
			@include media-breakpoint-down(md) {
				max-height: calc(100vh - 80px);
				max-height: 600px;
				overflow-y: auto;
			}

			> .nav-item {
				margin: 0 1rem;
				@include media-breakpoint-down(lg) {
					margin-bottom: 0.625rem;
				}

				&:last-of-type {
					margin-right: 0;
				}

				> a > span.menu-title {
					@include media-breakpoint-down(lg) {
						text-decoration: none;
						position: relative;
						font-weight: 600;
						cursor: pointer;
						font-size: 1rem;
					}
				}

				span.menu-title {
					font-size: 14px;
					font-weight: 600;
				}

				> a.nav-link {
					padding: 5px;
					position: relative;
					@include media-breakpoint-down(lg) {
						padding: 0;
					}

					&:before {
						content: "";
						position: absolute;
						left: 50%;
						width: 0;
						opacity: 0;
						height: 2px;
						border-radius: 2px;
						background: $primary;
						bottom: -3px;
						transform: translateX(-50%);
						transition: all 0.3s;
					}

					&:hover {
						&:before {
							width: 30px;
							opacity: 1;
						}
					}

					&.dropdown-toggle {
						&:after {
							display: none;
						}
					}
				}

				.dropdown-menu {
					margin-top: 30px;
					box-shadow: 0 10px 30px rgba(0, 0, 0, .10);
					left: -20px !important;
					padding: 1rem 0;
					opacity: 0;
					visibility: hidden;
					transition: all 0.2s;
					display: block;
					transform: none !important;
					border-radius: 0 0 1.5625rem 0;
					@include media-breakpoint-down(lg) {
						opacity: 1;
						visibility: visible;
						border: none;
						margin-top: 0;
						padding: 0;
						position: static !important;
					}

					&:after {
						content: '';
						width: 100%;
						height: 30px;
						position: absolute;
						bottom: 100%;
						left: 0;
					}

					> li {
						> a {
							color: inherit;
							display: block;
							width: 100%;
							padding: 0.25rem 1.5rem !important;
							white-space: nowrap;
							@include media-breakpoint-down(lg) {
								border-left: 1px solid #D8D8D8;
								font-size: 0.8125rem;
								padding: 0.25rem 1rem !important;
							}

							&:hover {
								color: $secondary;
							}
						}
					}
				}

				&:hover {
					.dropdown-menu {
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}
	}

	// site-switcher
	.site-switcher {
	}
}

// home
&.home {
	.header {
		@include media-breakpoint-up(xl) {
			position: fixed;
		}
	}
}
