&.default .collection.grid .grid-items {
	.item {
		@include media-breakpoint-up(md) {
			padding-left: 30px;
			padding-right: 30px;
		}

		&:nth-of-type(3n + 1) {
			@include media-breakpoint-up(md) {
				padding-left: 15px;
			}
		}

		&:nth-of-type(3n + 3) {
			@include media-breakpoint-up(md) {
				padding-right: 15px;
			}
		}

		.card {
			border-radius: 0 2.5rem 0 0;
			overflow: hidden;
			border: none;
			box-shadow: 0 10px 30px #00000010;
			-moz-box-shadow: 0 10px 30px #00000010;
			-webkit-box-shadow: 0 10px 30px #00000010;
			transition: all 0.15s ease-in-out;

			h4 {
				display: none;
			}

			&:hover {
				box-shadow: 0 0.625rem 40px #00000020;
			}

			.card-title-link {
				display: flex !important;
				flex-flow: row nowrap;
			}
		}
	}
}

.card {
	h3.card-title {
		font-size: $h4-font-size;
	}

	.card-body {
		padding: 1.25rem !important;
	}

	.card-btn {
		text-transform: none;

		&:after {
			content: url('/images/arrow.svg');
			margin-left: 10px;
			position: relative;
			top: -1px;
		}
	}

	&.icon {
		overflow: visible !important;

		.card-image {
			position: absolute !important;
			left: 30px;
			top: -30px;
		}

		.card-body {
			padding: 3.0625rem 30px 3.125rem !important;
		}

		.card-buttons {
			display: none;
		}
	}
}


.grid-items {
	.item {
		&:nth-of-type(3n + 2) {
			transition-delay: .1s;
		}

		&:nth-of-type(3n + 3) {
			transition-delay: .2s;
		}
	}
}

.custom-card {
	background: url('/images/gerrit.png');
	height: 100%;
	background-size: 90%;
	background-repeat: no-repeat;
	background-position: 50% 0;
	@include media-breakpoint-down(xs) {
		display: none;
	}

	span {
		font-family: $font-family-cave;
		color: #5C7D38;
		position: relative;
		font-size: 2rem;
		max-width: 200px;
		display: block;
		font-weight: 700;
		line-height: 1.875rem;
		top: 50px;

		&:after {
			content: url('/images/gerrit_arrow.svg');
			position: absolute;
			left: 20px;
			top: calc(100% + 5px);
		}
	}
}

&.service-overview {
	.custom-card {
		background: url('/images/gerrit-fam.png');
		background-size: cover;
		background-position: 80%;
	}
}

.categories-overview.collection {
	.grid-items {
		@include media-breakpoint-down(md) {
			display: flex;
			flex-flow: row nowrap;
			overflow-x: scroll;
			justify-content: flex-start;
		}

		.item {
			@include media-breakpoint-down(md) {
				margin-top: 30px;
				flex: 0 0 75%;
				max-width: 75%;
			}

			&:nth-of-type(3) {
				display: none;
			}
		}
	}
}

.card-tags {
	display: flex;
	flex-flow: row;
	overflow: visible;
	z-index: +1;
	margin-top: -50px;
	margin-bottom: 25px;

	.card-tags-item-title {
		display: none;
	}
}

.card-caption {
	.card-subtitle {
		font-weight: 400;
		font-size: 16px;
		display: block !important;
		border-bottom: 1px solid #D8D8D8;
		padding-bottom: 1.3rem;
		margin-bottom: 1.3rem !important;
	}

	.card-description {
		font-weight: 600;

		.card-description-content {
			margin-bottom: 15px;

			p {
				margin-bottom: 0;

				strong {
					font-size: 0.875rem;
					font-weight: 600;
					color: #abadb7;
				}
			}
		}
	}
}

.contentblock.slider {
	.card-buttons {
		.card-btn {
			@extend .btn-primary;
		}
	}
}

.card .card-image {
	max-height: 210px;
}

.owl-item {
	.item {
		overflow: visible !important;
	}
}
