// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.default-card {
}

.container {
	@media(min-width: 992px) {
		padding: 0 1.875rem !important;
	}
	// .container-big container-max-widths
	&-big {
		width: 100%;
		margin: 0 auto;
		padding: 0 15px;
		@include make-container-max-widths($container-max-widths-big, $grid-breakpoints-big);
	}
}

h1, h2, h3, h4, h5 {
	color: $body-color;
}


.container.align-center {
	> .container-holder {
		display: flex;
		align-items: center;
	}
}

&.landing-page {
	background: #fff !important;

	.main > section:first-of-type {
		padding-top: 6vh;
		background: #fff;
	}

	h1, h2, h3, h4, h5 {
		position: relative;
		padding-left: 35px;
		margin-left: -35px;
		margin-bottom: 25px;

		span {
			position: absolute;
			left: 0;
		}
	}

	.col-4,
	.col-8 {
		@include media-breakpoint-down(sm) {
			flex: 0 0 100% !important;
			max-width: 100% !important;
		}
	}

	.col-4 {
		@include media-breakpoint-down(sm) {
			order: -1;
			text-align: center;
		}
	}
}

.extra-padding-bottom {
	padding-bottom: 315px !important;

	+ section {
		transform: translateY(-160px);
	}
}

.wysiwyg {
	.btn {
		margin-right: 30px;
	}
}

.pagetitle-container {
	position: absolute;
	z-index: 2;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	@include media-breakpoint-down(md) {
		margin-top: 0;
	}

	.page-block.title {
		h1 {
			color: #fff;
		}
	}

	.wysiwyg {
		img {
			max-width: 590px;
			@include media-breakpoint-down(sm) {
				max-width: 100%;
			}
		}

		p {
			color: #fff;
		}
	}
}


&.two-column-page {
	.main {
		background: $gray-light;

		.content-section {
			&:first-of-type {
				padding-bottom: 0;
			}
		}
	}
}


&.contact-page {
	.wysiwyg {
		margin-bottom: 15px;
	}
}


&.blog-post-overview {
	background: $gray-light;

	.card-caption {
		margin-bottom: 15px;
	}

	.card-btn {
		background: $cta-green;
		border-color: $cta-green;
	}
}

&:not(.home) {
	background: $gray-light;

	.eyecatcher {
		margin-top: 80px;
		@include media-breakpoint-down(xl) {
			margin-top: 60px;
		}
	}
}

&.service-detail {
	background: #fff;

	.lead-section {
		margin-top: -150px !important;
		position: relative;
		z-index: +1;
		margin-bottom: 50px;
		@include media-breakpoint-down(md) {
			margin-bottom: 20px;
		}

		.container {
			.container-holder {
				max-width: 100%;
			}
		}

		h1, h5 {
			color: #fff !important;
		}

		h5 {
			font-weight: 400 !important;
			font-size: 16px;
		}

		.info {
			display: flex;
			flex-flow: row wrap;

			> .text-wrapper {
				margin-right: 15px;
			}
		}
	}

	.gallery-section {
		.nav {
			width: 100%;
			border-bottom: 1px solid #D8D8D8;
			min-height: 42px;
			@include media-breakpoint-down(md) {
				padding: 0 15px;
				display: flex;
				overflow: auto;
				border-bottom: 0;
			}

			.nav-item {
				margin-bottom: -1px;
				@include media-breakpoint-down(md) {
					flex: 0 0 100%;
					max-width: 100%;
					margin-bottom: 0;
				}

				.nav-link {
					border-top-right-radius: 0.75rem;
					border-bottom: 1px solid #F8F6F3;
					white-space: nowrap;
					@include media-breakpoint-down(md) {
						border: 1px solid #ffffff !important;
					}

					&.active {
						border: 1px solid #D8D8D8;
						border-bottom: 1px solid #ffffff;
						@include media-breakpoint-down(md) {
							border: 1px solid #D8D8D8 !important;
						}
					}
				}
			}
		}
	}

	[class*='js-tab'] {
		margin-bottom: 100px;
	}

	.js-tab-general {
		.container-holder {
			.column {
				padding: 0;
			}
		}
	}

	.js-tab-contact,
	.js-tab-services {
		.wysiwyg,
		.contact-info {
			@include media-breakpoint-down(md) {
				padding: 0 15px;
			}
		}
	}

	.read-more-wrapper {
		margin-top: 50px;
		margin-bottom: 50px;
		@include media-breakpoint-down(md) {
			padding: 0 15px;
		}

		.read-more-text {
			max-height: 210px;
			overflow: hidden;
			position: relative;
			margin-bottom: 15px;

			&:after {
				background: linear-gradient(to bottom, #ffffff00 0%, #ffffff 100%);
				bottom: 0;
				content: "";
				height: 160px;
				left: 0;
				pointer-events: none;
				position: absolute;
				right: 0;
				transition: all 0.15s;
			}
		}

		.read-more-button {
			font-weight: 700;
			cursor: pointer;

			&.read-less {
				display: none;
			}
		}

		&.open {
			.read-more-text {
				max-height: none;

				&:after {
					display: none;
				}
			}

			.read-more-button {
				&:not(.read-less) {
					display: none;
				}

				&.read-less {
					display: inline-block;
				}
			}
		}
	}

	.quote-wrapper {
		font: 700 2rem/0.875 "Caveat", cursive;
		color: #5C7D38;
		position: relative;
		width: 100%;
		@include media-breakpoint-down(md) {
			padding: 0 15px;
			flex-flow: row wrap;
		}

		.wysiwyg {
			p:not(:first-of-type) {
				position: absolute;
				top: 50%;
				left: 350px;
				max-width: 330px;
				transform: translateY(-50%);
				margin-top: 20px;
			}
		}

		.quote_image_wrapper {
			@include media-breakpoint-down(md) {
				margin-bottom: 35px;
			}
		}

		> div {
			@include media-breakpoint-down(md) {
				flex: 0 0 100%;
				max-width: 100%;
			}
		}
	}

	.back-to-overview-section {
		background: #F8F6F3;
		padding: 3.75rem 0 !important;
		@include media-breakpoint-down(md) {
			padding: 3.75rem 15px !important;
		}

		h3 {
			display: block;
			width: 100%;
			margin-bottom: 15px;
		}

		a {
			position: relative;

			&:after {
				position: absolute;
				left: 0;
				top: 50%;
				transform: rotate(180deg) translateY(50%);
				color: $primary;
			}
		}
	}
}

[class*='col-'] {
	@include media-breakpoint-down(xs) {
		flex: 0 0 100% !important;
		max-width: 100% !important;
	}
}

&.gids-page {
	.pagetitle-container {
		.title {
			@include media-breakpoint-down(md) {
				top: -340px;
				position: absolute;
			}
		}

		.wysiwyg {
			position: absolute;
			bottom: -65px;
			right: 0;
		}
	}
}

.overflow-hidden {
	overflow: hidden;
}
