&.faq-overview {
	.faq-overview-section {
		.column {
			&.one {
				flex: 0 0 75%;
				max-width: 75%;
				@include media-breakpoint-down(md) {
					flex: 0 0 100%;
					max-width: 100%;
				}
			}

			&.two {
				flex: 0 0 25%;
				max-width: 25%;
				@include media-breakpoint-down(md) {
					display: none;
				}
			}
		}

		.faq-items {
			background: #fff;
			border-radius: 0 2.5rem 0 0;
			padding: 0.5rem 1.25rem !important;

			.faq-item {
				.title {
					padding: 1.25rem 0.5rem !important;
					min-height: 70px;
					display: flex;
					align-items: center;
					padding-right: 40px !important;
				}

				.faq-question {
					padding: 0 !important;
					margin: 0 !important;
				}

				.faq-answer {
					border: none !important;
					padding: 0 0.5rem !important;
				}

				.faq-icon {
					left: auto;
					right: 5px;
					top: 50%;
					transform: translateY(-50%);
				}

				&:not(:first-of-type) {
					.faq-question {
						border-top: 1px solid #eeeef0;
					}
				}

				&.active {
					.faq-question {
						.title {
							h3 {
								color: $cta-green;
								max-width: 90%;
							}
						}
					}
				}
			}
		}
	}
}
