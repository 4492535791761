// btn
.btn {
	box-shadow: $shadow;
	font-weight: 700;
	text-transform: none;
	text-decoration: none;
	border-radius: 10rem;
	padding: 0.5625rem 1.5rem;
	margin-bottom: 15px;
	// btn-primary
	&.btn-primary {
		&:hover {
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		background: transparent;

		&:hover {
			background: $primary;

			&:after {
				content: url('/images/arrow.svg');
			}
		}

		&:after {
			content: url('/images/arrow-gray.svg');
		}
	}

	// btn-secondary
	&.btn-secondary {
		color: #fff;

		&:hover {
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {
		}
	}

	&:after {
		content: url('/images/arrow.svg');
		margin-left: 10px;
		position: relative;
		top: -1px;
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-secondary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}

