// footer
.footer {
	padding-top: 110px;
	background: #fff;
	@include media-breakpoint-down(md) {
		padding-top: 35px;
	}

	.footer-outro {
		margin-bottom: 110px;
		@include media-breakpoint-down(md) {
			margin-bottom: 35px;
		}

		h4 {
			color: $primary;
			margin-bottom: 15px;
		}

		h6 {
			font-weight: 400;
			max-width: 350px;
			margin-bottom: 15px;
		}

		.footer-text {
			img {
				margin-right: 15px;
				margin-bottom: 20px;
			}
		}

		ul.vocabulary-term-list {
			li {
				margin-right: 15px;

				a {
					width: 40px;
					height: 40px;
					display: inline-flex;
					justify-content: center;
					align-items: center;
					border-radius: 50px;
					background: $pink;
					color: #fff;
					font-size: 22px;
					text-decoration: none;

					.list-item-title {
						display: none;
					}
				}
			}
		}
	}

	.footer-socket {
		.footer-link {
			border-top: 1px solid #D8D8D8;

			.list {
				display: flex;
				flex-flow: row wrap;
				padding-top: 30px;
				padding-bottom: 30px;

				.list-item {
					flex: 0 0 25%;
					max-width: 25%;
					@include media-breakpoint-down(md) {
						flex: 0 0 50%;
						max-width: 50%;
					}
					@include media-breakpoint-down(xs) {
						flex: 0 0 100%;
						max-width: 100%;
					}

					a {
						font-size: 0.875rem;
						color: #cccdd4;
					}
				}
			}
		}
	}
}
