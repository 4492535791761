// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}

	&.bg-dark {
		background: $green-light !important;
	}

	&.bg-light {
		background: $gray-light !important;

		* {
			color: $gray;
		}
	}

	.container-big.bg-green {
		> .container-holder {
			background: $cta-green;
			border-radius: 0 2.5rem 0 0;
			min-height: 322px;
			display: flex;
			align-items: center;
			padding-top: 25px;
			padding-bottom: 25px;
			@include media-breakpoint-down(md) {
				border-radius: 0;
			}

			* {
				color: #fff;
			}

			.column.col-5 {
				padding-right: 0 !important;
				padding-left: 80px;
				@include media-breakpoint-down(md) {
					max-width: 100%;
					width: 100%;
					flex: 0 0 100%;
					padding-left: 15px;
				}
			}

			.col-7 {
				@media(max-width: 991px) {
					padding-top: 100px;
					flex: 0 0 100%;
					max-width: 100%;
				}
			}

			.btn-primary {
				&:not(:hover) {
					color: $gray;
					background: #fff;
					border-color: #fff;
				}
			}
		}
	}
}

// lead-section
// =========================================================================
&.home {
	.lead-section {
		margin-top: 16vh;
		margin-bottom: 10vh;
		@include media-breakpoint-down(md) {
			margin-top: 6vh;
			margin-bottom: 6vh;
		}
	}
}

.eyecatcher + .main {
	> .lead-section:first-of-type {
		margin-top: 5vh;
	}
}

.lead-section {
	margin-top: 150px;

	.container-one-column {
		.container-holder {
			max-width: 875px;

			.page-title {
				h1 {
					font-size: $h2-font-size;
				}
			}

			.wysiwyg {
				p {
					font-size: $font-size-big;
					font-weight: 700;
					@include media-breakpoint-down(sm) {
						font-size: 1rem;
					}
				}
			}
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}
}

// content-section
// =========================================================================
.content-section {
	padding: 6vh 0;

	&:first-of-type {
		padding-top: 160px;
	}

	// intro/outro
	.intro,
	.outro {
		max-width: 750px;

		.container-holder {
			text-align: center;
		}
	}

	.intro {
		.container-holder {
			margin-bottom: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.outro {
		.container-holder {
			margin-top: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	padding: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	padding: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	padding: 6vh 0;
}

// booking-section
// =========================================================================
.booking-section {
	padding: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// banner-section
// =========================================================================

.banner-section {
	.container-big {
		position: relative;

		&:after {
			content: '';
			background: url('/images/banner_balls.svg');
			width: 330px;
			height: 330px;
			position: absolute;
			right: 95px;
			bottom: 45px;
			transform: translate(50%, 50%);
			z-index: 1;
			@media(max-width: 1199px) {
				bottom: 45px;
				transform: translate(0, 50%);
				width: 150px;
				height: 150px;
				background-size: cover;
			}
			@include media-breakpoint-down(md) {
				right: 0;
				bottom: 0;
				transform: scale(.5);
			}
		}

		.page-block.image-collection {
			.owl-stage-outer {
				&:before {
					content: url('/images/bannericon.svg');
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					z-index: 1;
					@include media-breakpoint-down(md) {
						transform: translate(-50%, -50%) scale(.3);
					}
				}

				.item {
					min-height: 400px !important;
				}
			}
		}
	}
}

// slider-section
// =========================================================================

.slider-section {
	position: relative;
	overflow: hidden;
	max-width: 100%;

	&:before,
	&:after {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		width: 1.875rem;
		z-index: 10;
		@include media-breakpoint-up(md) {
			width: calc((100vw - 1180px) / 2);
		}
	}

	&:after {
		right: 0;
		background: linear-gradient(to right, #E2F0CF00 0%, #E2F0CF90 50%, #E2F0CF 100%);
	}

	&:before {
		left: 0;
		background: linear-gradient(to right, #E2F0CF 0%, #E2F0CF80 40%, #E2F0CF00 100%);
	}

	.container {
		padding: 0 15px !important;
	}

	.page-block.wysiwyg {
		padding-left: 15px;
		margin-bottom: 15px;

		h2 {
			color: $primary;
		}
	}
}


.introduction-section {
	padding: 50px 0;

	.container {
		margin-bottom: 35px;

		&:nth-of-type(2n + 1) {
			.container-holder {
				@include media-breakpoint-up(md) {
					max-width: 66.666%;
					margin-left: auto;
				}
			}
		}

		&:nth-of-type(2n + 2) {
			.container-holder {
				@include media-breakpoint-up(xl) {
					max-width: 900px;
					margin-right: auto;
					transform: translateX(-120px);
				}

				.wysiwyg {
					p {

						max-height: 570px;
						column-width: 410px;
						column-gap: 30px;
						@include media-breakpoint-down(md) {
							column-width: 300px;
						}
						@include media-breakpoint-down(sm) {
							column-width: auto;
							max-height: none;
							text-align: center;
						}

						img {
							&:first-of-type {
								margin-top: 160px;
								border-radius: 0 0 2.5rem 0;
							}

							&:nth-of-type(2) {
								border-radius: 0 2.5rem 0 0;
								margin-bottom: 2.1875rem;
							}

							&:nth-of-type(3) {
								border-radius: 0 0 0 2.5rem;
								max-width: 351px;
							}

							@include media-breakpoint-down(sm) {
								border-radius: 0 !important;
								margin-bottom: 15px;
								max-width: 100% !important;
								margin-top: 0 !important;
							}
						}
					}
				}
			}
		}
	}
}

&.landing-page {
	.container {
		margin-bottom: 35px;

		.container-holder {
			max-width: 100% !important;
			transform: none !important;
		}
	}
}

.faq-overview-section {
	padding-bottom: 70px;
}

.back-to-overview-section {
	.container-holder {
		position: relative;

		.detail-footer-balls {
			position: absolute;
			right: 0;
			top: 0;
			@media(min-width: 768px) {
				transform: translateY(-50%);
			}
		}
	}
}
