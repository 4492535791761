.service-tags.filter-tags {
	margin-top: 1rem;
	margin-bottom: 3rem;
	gap: 0;
	overflow-x: auto;
	flex-flow: row wrap;
	justify-content: center;

	@include media-breakpoint-up(sm) {
		margin-top: -75px;
		margin-bottom: 100px;
	}

	a {
		background: $white;
		border: 1px solid #eeeef0;
		border-radius: 0 0.75rem 0 0;
		flex: 0 0 auto;
		font-size: 0.875rem;
		font-weight: 600;
		margin: 0 1.25rem 1.25rem 0;
		padding: 0.375rem 0.875rem;
		position: relative;

		&:hover {
			background: transparent;
			border-color: rgba(87, 91, 112, 0.5);
		}

		.filter-tag-icon {
			max-width: 25px;
		}
	}
}

// search form in aanbod page
.searchbox {
	flex: 0 1 300px;
	max-width: 300px;
	form {
		display: flex;

		.btn {
			@extend .btn-secondary;
			margin-bottom: 0;
			margin-left: 10px;
			font-size: 0.875rem;
			padding: 0.375rem 1.5rem;
		}
	}
}


&.service-overview {
	.lead-section {
		margin-top: 0 !important;
		padding-top: 6vh;
		background: #fff;
		padding-bottom: 1rem;
		@include media-breakpoint-up(sm) {
			padding-bottom: 100px;
		}

		.container {
			.container-holder {
				max-width: unset;

				.wysiwyg {
					position: relative;
					padding: 0.75rem 1.25rem;
					margin-bottom: 1rem;
					border: 1px solid #d6e9f9;
					border-radius: 0.75rem;
					background-color: #e2f0fb;
				}

				p {
					color: #385d7a;
					font-size: 1rem;
					font-weight: 400;
					line-height: 1.5;
				}
			}
		}
	}

	.bundle-overview-section {
		padding-top: 0;
	}
}

.contact-info {
	font: 700 2rem/0.875 "Caveat", cursive;
	color: #5C7D38;
	max-width: 420px;
	position: relative;

	&:before {
		content: '';
		background: url('/images/gerrit-circle.png');
		width: 136px;
		height: 172px;
		display: inline-block;
		background-size: cover;
		position: absolute;
		left: 0;
		@include media-breakpoint-down(md) {
			left: 15px;
		}
	}

	&:after {
		content: url('/images/gerrit_arrow.svg');
		position: absolute;
		transform: rotate(190deg);
		top: 0;
		left: 120px;
		@include media-breakpoint-down(md) {
			transform: rotate(280deg);
			top: 80px;
			left: 135px;
		}
	}

	span {
		width: 100%;
		display: block;
		padding-left: 160px;
		margin-top: 50px;
		@include media-breakpoint-down(md) {
			padding-left: 0;
			padding-top: 130px;
		}
	}
}

.quote-wrapper {
	display: flex;
	align-items: flex-end;

	.service_quote_image {
		width: 164px;
		height: 164px;
		border-radius: 50%;
		margin-left: -115px;
		margin-bottom: -100px;
	}

	.wysiwyg {
		max-width: 300px;
		margin-left: 25px;
		@include media-breakpoint-down(md){
			margin-left:0;
		}
	}
}
